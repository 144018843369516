import { Grid } from '@helpers/grid';
import { WYSIWYGStyles } from '@stories/Components/Content/WYSIWYG/WYSIWYG.styles';
import Heading from '@stories/Components/Global/Typography/Heading';
import React, { useMemo, useRef, useState } from 'react';
import { ProductSpecificationsStyles as S } from './ProductSpecifications.styles';
import { ReactComponent as Chevron } from '@img/icons/chevron.svg';
import FileCard from '@stories/Components/Cards/FileCard/FileCard';
import Flicking, { ALIGN } from '@egjs/react-flicking';
import { useDispatch, useSelector } from 'react-redux';
import { LoginRedirectType } from '@redux/ducks/app/app.reducer';
import withWidget from '@hoc/withWidget';
import withRedux from '@helpers/withRedux';
import { HydrateOption } from '@core/enums';
const PAGE_SIZE = 4;
const ProductSpecifications = (props) => {
    const [tab, setTab] = useState(props.productDetailsContent
        ? 0
        : props.productSpecifications
            ? 1
            : props.faqs && props.faqs.length > 0
                ? 2
                : 3);
    const [activeFAQ, setActiveFAQ] = useState(0);
    const [index, setIndex] = useState(0);
    const sliderRef = useRef(null);
    const pageCount = useMemo(() => {
        const count = Math.ceil(props.relatedFiles.length / PAGE_SIZE);
        return count - 1;
    }, [props.relatedFiles]);
    const paginatedFiles = useMemo(() => {
        const files = {};
        for (let index = 0; index <= pageCount; index++) {
            const tempFiles = [...props.relatedFiles];
            const pageFiles = [...tempFiles.splice(index * PAGE_SIZE, PAGE_SIZE)];
            files[index] = pageFiles;
        }
        return files;
    }, [props.relatedFiles]);
    const appState = useSelector((x) => x.app);
    const dispatch = useDispatch();
    return (React.createElement(S.OuterGrid, null,
        React.createElement(S.Tabs, null,
            React.createElement(S.TabsInner, null,
                props.productDetailsTitle && props.productDetailsContent && (React.createElement(S.Tab, { isActive: tab === 0, onClick: () => setTab(0) }, props.productDetailsTitle)),
                props.productSpecificationTitle && props.productSpecifications && (React.createElement(S.Tab, { isActive: tab === 1, onClick: () => setTab(1) }, props.productSpecificationTitle)),
                props.faqTitle && props.faqs && props.faqs.length > 0 && (React.createElement(S.Tab, { isActive: tab === 2, onClick: () => setTab(2) }, props.faqTitle)),
                props.relatedFilesTitle && props.relatedFiles && props.relatedFiles.length > 0 && (React.createElement(S.Tab, { isActive: tab === 3, onClick: () => setTab(3) }, props.relatedFilesTitle)))),
        React.createElement(S.Background, null,
            React.createElement(Grid.Inner, null,
                React.createElement(S.ContentWrapper, { isActive: tab === 0 }, props.productDetailsContent ? (React.createElement(S.Details, { dangerouslySetInnerHTML: { __html: props.productDetailsContent } })) : null),
                React.createElement(S.TableWrapper, { isActive: tab === 1 }, props.productSpecifications ? (React.createElement(WYSIWYGStyles.Wrapper, { dangerouslySetInnerHTML: { __html: props.productSpecifications } })) : null),
                React.createElement(S.TabInner, { centerTitle: true, thin: true, isActive: tab === 2 },
                    React.createElement(Heading, { semantic: "h2", visual: "h5" }, props.faqTitle),
                    React.createElement(S.FAQs, null, props.faqs &&
                        props.faqs.map((x, i) => (React.createElement(S.FAQ, { key: x.question, isActive: activeFAQ === i },
                            React.createElement(S.FAQQuestion, { onClick: () => setActiveFAQ(activeFAQ === i ? -1 : i) },
                                x.question,
                                React.createElement(Chevron, null)),
                            React.createElement(S.FAQAnswer, { dangerouslySetInnerHTML: { __html: x.answer } })))))),
                React.createElement(S.TabInner, { isActive: tab === 3 },
                    props.relatedFiles && props.relatedFiles.length > 0 ? (React.createElement(Heading, { semantic: "h2", visual: "h5" }, props.relatedFilesTitle)) : null,
                    React.createElement(S.Files, null,
                        React.createElement(Flicking, { ref: sliderRef, align: ALIGN.PREV, onWillChange: (e) => {
                                setIndex(e.index);
                            } }, Object.keys(paginatedFiles).map((p) => (React.createElement(S.FilePage, { key: p }, paginatedFiles[parseInt(p, 10)].map((f) => (React.createElement(FileCard, { key: f.url, file: f, redirectUrl: props.relatedFilesRedirectUrl, onClick: (e) => {
                                if (!appState.isLoggedIn) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    dispatch({
                                        type: 'APP/SET_LOGIN_MODAL',
                                        payload: {
                                            loginType: LoginRedirectType.Default,
                                            redirectUrl: f.url,
                                        },
                                    });
                                }
                            } })))))))),
                    pageCount > 0 && (React.createElement(S.FilesFooter, null,
                        React.createElement(S.Progress, { width: 100 / (pageCount + 1), offset: 100 * index }),
                        React.createElement(S.MediaTrackArrow, { disabled: index === 0, onClick: () => sliderRef.current?.prev() },
                            React.createElement(Chevron, null)),
                        React.createElement(S.MediaTrackArrow, { disabled: index === pageCount, onClick: () => sliderRef.current?.next() },
                            React.createElement(Chevron, null)))))))));
};
export default withWidget(withRedux(ProductSpecifications), 'ProductSpecifications', {
    hydrate: HydrateOption.InView,
});
