import brand from '@helpers/brand';
import { FlickingCss } from '@helpers/flickingStyles';
import { fonts } from '@helpers/fonts';
import { ButtonReset } from '@helpers/global';
import { Grid } from '@helpers/grid';
import { Device, from, until } from '@helpers/media';
import HeadingStyles from '@stories/Components/Global/Typography/Heading.styles';
import ParagraphStyles from '@stories/Components/Global/Typography/Paragraph.styles';
import styled, { css } from 'styled-components';
const OuterGrid = styled(Grid.Default) `
  row-gap: 0px;
`;
const TabInner = styled.div `
  margin: 50px 0px;

  ${HeadingStyles.Heading} {
    color: ${brand.primary.alt};
  }

  h2 {
    color: ${brand.primary.alt};
  }

  ${({ centerTitle }) => centerTitle === true
    ? css `
          ${HeadingStyles.Heading} {
            text-align: center;
            color: ${brand.primary.alt};
          }
        `
    : null}

  ${({ thin }) => thin
    ? css `
          grid-column: span 12;

          @media ${from(Device.DesktopSmall)} {
            grid-column: 2 / 12;
          }

          @media ${from(Device.DesktopLarge)} {
            grid-column: 4 / 10;
          }
        `
    : css `
          grid-column: span 12;

          @media ${from(Device.DesktopSmall)} {
            grid-column: 2 / 12;
          }
        `}

  ${({ isActive }) => isActive === false
    ? css `
          display: none;
        `
    : null}
`;
const Background = styled.div `
  background-color: ${brand.grey['grey96']};
  grid-column: 2 / span 10;

  @media ${until(Device.DesktopSmall)} {
    ${Grid.DefaultPseudo};
    grid-column: span 12;

    &:before,
    &:after {
      background-color: ${brand.grey['grey96']};
    }
  }
`;
const TableWrapper = styled.div `
  grid-column: span 12;

  ${({ isActive }) => isActive === false
    ? css `
          display: none;
        `
    : null}
`;
const ContentWrapper = styled.div `
  grid-column: span 12;
  padding: 50px 0px;

  @media ${from(Device.Tablet)} {
    padding: 50px 25px;
  }

  @media ${from(Device.Desktop)} {
    padding: 50px;
  }
  ${({ isActive }) => isActive === false
    ? css `
          display: none;
        `
    : null}
`;
const Tabs = styled.div `
  grid-column: 2 / span 10;
  position: relative;

  @media ${until(Device.DesktopSmall)} {
    grid-column: span 12;
  }

  /* Overflow indicator overlay */
  @media ${until(Device.DesktopSmall)} {
    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background-image: linear-gradient(to right, transparent, white);
    }
  }
`;
const TabsInner = styled.div.attrs({ role: 'tabset' }) `
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: auto;

  @media ${until(Device.DesktopSmall)} {
    padding-right: 20px;
  }
`;
const Tab = styled.button.attrs({ type: 'button', role: 'tab' }) `
  ${ButtonReset};
  padding: 12px 20px;
  background-color: ${brand.white};
  border: 1px solid ${brand.primary.base};
  margin-right: 6px;
  ${fonts.DaxPro.Bold};
  color: ${brand.black};
  font-size: 16px;
  flex: 0 0 auto;

  ${({ isActive }) => isActive
    ? css `
          background-color: ${brand.primary.base};
          color: ${brand.white};
        `
    : null}

  &:last-child {
    margin-right: 0px;
  }

  @media ${from(Device.TabletLarge)} {
    font-size: 20px;
    margin-right: 2px;
    min-width: 250px;
  }
`;
const FAQs = styled.div `
  display: flex;
  flex-direction: column;
`;
const FAQQuestion = styled.h3 `
  ${fonts.DaxPro.Regular};
  font-size: 24px;
  color: ${brand.grey.grey20};
  padding-right: 60px;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;

  > svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 29px;
    height: 14px;
    stroke: ${brand.black};
    transform: rotate(180deg);
  }
`;
const FAQAnswer = styled.div `
  ${ParagraphStyles.RegularStyles};
  display: none;
  margin-top: 12px;
  margin-bottom: 0px;

  a {
    color: ${brand.primary.alt};
  }
`;
const FAQ = styled.div `
  padding-bottom: 22px;
  border-bottom: 2px solid ${brand.primary.base};
  margin-bottom: 28px;

  ${({ isActive }) => isActive
    ? css `
          ${FAQAnswer} {
            display: block;
          }

          ${FAQQuestion} > svg {
            transform: rotate(0deg);
          }
        `
    : css `
          ${FAQAnswer} {
            display: none;
          }
        `}
`;
const Files = styled.div `
  ${FlickingCss};
`;
const FilePage = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  grid-gap: 20px;
  width: 100%;
  margin-right: 20px;

  @media ${from(Device.TabletLarge)} {
    grid-gap: 30px;
    margin-right: 30px;
  }
`;
const FilesFooter = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;

  @media ${from(Device.TabletLarge)} {
    margin-top: 40px;
  }
`;
const Progress = styled.div `
  height: 4px;
  background-color: ${brand.grey.grey89};
  width: 100%;
  position: relative;
  margin-right: auto;
  flex: 0 1 auto;

  @media ${from(Device.TabletLarge)} {
    max-width: 50%;
  }

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    background-color: ${brand.grey.grey20};
    width: ${({ width }) => width}%;
    transform: ${({ offset }) => `translateX(${offset}%)`};
  }
`;
const MediaTrackArrow = styled.button.attrs({ type: 'button' }) `
  ${ButtonReset};
  background-color: ${brand.primary.base};
  width: 44px;
  height: 44px;
  flex: 0 0 auto;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:nth-child(2) {
    transform: rotate(-90deg);
    margin-left: 25px;
  }

  &:nth-child(3) {
    transform: rotate(90deg);
    margin-left: 6px;
  }

  svg {
    width: 28px;
    height: 14px;
    stroke: ${brand.white};
    stroke-width: 2px;
  }
`;
const Details = styled.ul `
  list-style-type: none;
  padding: 0;

  li {
    ${ParagraphStyles.SmallStyles};
    margin-bottom: 5px;
    padding-left: 44px;
    position: relative;
    text-indent: -20px;

    &:before {
      content: '';
      display: block;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      background-color: ${brand.primary.base};
      position: absolute;
      top: calc(0.5em - 3x);
      left: 10px;
    }

    a {
      color: ${brand.primary.alt};
    }
  }

  p {
    ${ParagraphStyles.RegularStyles};
  }

  a {
    color: ${brand.primary.alt};
  }
`;
export const ProductSpecificationsStyles = {
    OuterGrid,
    TabInner,
    Background,
    Tabs,
    TabsInner,
    Tab,
    FAQs,
    FAQ,
    FAQQuestion,
    FAQAnswer,
    Files,
    FilePage,
    FilesFooter,
    Progress,
    MediaTrackArrow,
    TableWrapper,
    ContentWrapper,
    Details,
};
